// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aag-2-tsx": () => import("./../../../src/pages/aag2.tsx" /* webpackChunkName: "component---src-pages-aag-2-tsx" */),
  "component---src-pages-aag-2024-tsx": () => import("./../../../src/pages/aag2024.tsx" /* webpackChunkName: "component---src-pages-aag-2024-tsx" */),
  "component---src-pages-gbg-tsx": () => import("./../../../src/pages/gbg.tsx" /* webpackChunkName: "component---src-pages-gbg-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-malmo-tsx": () => import("./../../../src/pages/malmo.tsx" /* webpackChunkName: "component---src-pages-malmo-tsx" */)
}

